import React from "react";
import { Link } from "react-router-dom";
import styles from "./Content.module.scss";
import LinkButton from "../button/LinkButton";

const Content = ({
  host,
  about,
  learning,
  startDate,
  onRegistrationLinkClick,
  relatedWebinars,
}) => {
  const timeRemained = new Date(startDate).getTime() - Date.now();

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.main}>
          <div className={styles.host}>
            <h4 className={styles.heading}>Hosted By</h4>
            <div className={styles.content}>
              <div className={styles.atc}>
                <div className={styles.logo} />
                <h6 className={styles.title}>
                  Aftermarket Technology Connection
                </h6>
              </div>
              <div className={styles.hw}>
                <div
                  className={styles.logo}
                  style={{ backgroundImage: `url(${host.logo})` }}
                />
                <div className={styles.info}>
                  <div className={styles.title}>{host.name}</div>
                  <div className={styles.description}>{host.description}</div>
                  {host.link && (
                    <a
                      href={host.link}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.link}
                    >
                      Learn More
                      <div className={styles.arrow} />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.description}>
            <div className={styles.about}>
              <h3 className={styles.heading}>About This Event</h3>
              {about.map((paragraph, i) => (
                <p className={styles.paragraph} key={i}>
                  {paragraph}
                </p>
              ))}
            </div>
            <div className={styles.learn}>
              <div className={styles.heading}>What You’ll Learn</div>
              <div className={styles.items}>
                {learning.map((item, i) => (
                  <div className={styles.item} key={i}>
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
            {timeRemained > 0 ? (
              <div className={styles.button} onClick={onRegistrationLinkClick}>
                <LinkButton title="Register for Webinar" />
              </div>
            ) : null}
          </div>
        </div>
        {!relatedWebinars.length ? null : (
          <div className={styles.related}>
            <h3 className={styles.heading}>Related Webinars</h3>
            <div className={styles.cards}>
              {relatedWebinars.map((webinar, i) => (
                <div className={styles.card} key={i}>
                  <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${webinar.image})` }}
                  />
                  <div className={styles.body}>
                    <div className={styles.heading}>{webinar.topic}</div>
                    <div className={styles.description}>
                      {webinar.description}
                    </div>
                    <Link
                      to={`/${webinar.url}`}
                      reloadDocument
                      className={styles.link}
                    >
                      Learn More
                      <div className={styles.arrow} />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;
