import React from "react";
import styles from "./divider.module.scss";

export const HSplit = ({ className = "" }) => {
  return <div className={`${styles.hSplit} ${className}`}></div>;
};

export const VSplit = ({ className = "" }) => {
  return <div className={`${styles.vSplit} ${className}`}></div>;
};
