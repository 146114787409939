import React, { useState, useEffect } from "react";
import styles from "./Hero.module.scss";
import Header from "../header/index";
import Title from "./Title";
import Counter from "./Counter";
import RequestAccessForm from "./RequestAccessForm";

const Hero = ({
  startDate,
  duration,
  topic,
  description,
  onRegistrationLinkClick,
}) => {
  const [status, setStatus] = useState("upcoming");
  const [date, setDate] = useState(new Date(startDate));

  const timeRemained = date.getTime() - Date.now();

  useEffect(() => {
    const timeoutId = setTimeout(
      () => setStatus("finished"),
      (timeRemained > 0 && timeRemained) || 0
    );

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.heroWrapper}>
        <Header cls />
        <div className={styles.content}>
          <Title
            {...{
              status,
              date,
              duration,
              topic,
              description,
              onRegistrationLinkClick,
              isRegistrationActive: !!(timeRemained > 0),
            }}
          />
          {timeRemained > 0 ? <Counter date={date} /> : <RequestAccessForm />}
        </div>
      </div>
    </div>
  );
};

export default Hero;
