import React from "react";
import styles from "./Hero.module.scss";
import Header from "../header/index";
import Button from "../button/Button";
import { HeroIcon } from "../../assets/icons/hero";
import { useScreenSize } from "../../hooks";

const Hero = () => {
  const { isMobile } = useScreenSize();

  const scrollToCard = () =>
    document
      .querySelectorAll("[class^=PartnerCard_partnerCard]")[0]
      .scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

  return (
    <div className={styles.wrapper}>
      <div className={styles.heroWrapper}>
        <Header />
        <div className={styles.hero}>
          <div className={styles.heroText}>
            <div className={styles.heroTextWrap}>
              <h1 className={styles.heroTitle}>
                Driving Forward,{" "}
                <span className={styles.heroTitleMain}>Together</span>
              </h1>
              <p className={styles.heroBody}>
                The Aftermarket Technology Connection is here to help you take
                your shop to the next level with solutions built for your
                business and future.
              </p>
              <Button
                title="Get Started"
                variant="secondary"
                onClick={() => scrollToCard()}
              />
            </div>
          </div>
          <div className={styles.heroImgWrap}>
            <div className={styles.heroImg}>{!isMobile && <HeroIcon />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
