import React, { useState, useEffect } from "react";
import styles from "./SubscribePopup.module.scss";
import { About } from "./About";
import HubspotMeetingsForm from "./HubspotMeetingsForm";
import { Success as HubspotMeetingsSuccess } from "./HubspotMeetingsSuccess";
import { Error as HubspotMeetingsError } from "./HubspotMeetingsError";
import {
  ANALYTICS_EVENTS,
  addAnalyticsEvent,
} from "../../services/analyticsService";

const SubscribePopup = ({ userEmail = "", close }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState({});

  useEffect(() => {
    addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessPopupOpened);
  }, []);

  const handleClose = () => {
    setSuccess(false);
    close();
  };

  const handleLoad = () => {
    addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessFormShown);
  };

  const handleError = () => {
    setError(true);
  };

  const handleSuccess = ({ timestamp, name }) => {
    addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessFormSubmitted);
    setMeetingInfo({ timestamp, name });
    setSuccess(true);
  };

  return (
    <div className={styles.subscribePopup}>
      <div className={styles.popup}>
        <button className={styles.btnClose} onClick={handleClose}></button>
        <About onClose={close} />
        {error ? (
          <HubspotMeetingsError onTryAgain={() => setError(false)} />
        ) : success ? (
          <HubspotMeetingsSuccess meetingInfo={meetingInfo} />
        ) : (
          <HubspotMeetingsForm
            userEmail={userEmail}
            onLoad={handleLoad}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        )}
      </div>
    </div>
  );
};

export default SubscribePopup;
