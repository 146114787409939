import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Webinar from "./components/webinar";
import { initGTM } from "./services/analyticsService";

import WEBINARS from "./webinars";

function App() {
  useEffect(() => {
    initGTM();
  }, []);

  return (
    <div>
      <Routes>
        <Route index element={<Home />} />
        {WEBINARS.map((config, i) => (
          <Route
            key={i}
            path={`/${config.url}`}
            element={<Webinar {...config} allWebinars={WEBINARS} />}
          />
        ))}
      </Routes>
    </div>
  );
}

export default App;
