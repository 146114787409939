import React from "react";
import styles from "./PartnersLogos.module.scss";
import {
  TekmetricIcon,
  AdvancePprofessionalIcon,
  SteerIcon,
  TekmetricMobIcon,
  AdvancePprofessionalMobIcon,
  SteerMobIcon,
} from "../../assets/icons/header";
import { useScreenSize } from "../../hooks";
import cn from "classnames";

const PartnersLogos = ({ variant }) => {
  const { isMobile } = useScreenSize();

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.title, styles[`variant-${variant}`])}>
        Proud Partners:
      </div>
      <div className={cn(styles.logos, styles[`variant-${variant}`])}>
        {isMobile ? (
          <AdvancePprofessionalMobIcon />
        ) : (
          <AdvancePprofessionalIcon />
        )}
        <div className={styles.divider}></div>
        {isMobile ? <SteerMobIcon /> : <SteerIcon />}
        <div className={styles.divider}></div>
        {isMobile ? <TekmetricMobIcon /> : <TekmetricIcon />}
      </div>
    </div>
  );
};

export default PartnersLogos;
