import cn from "classnames";
import styles from "./Button.module.scss";

export default function Button({
  title,
  onClick,
  className,
  variant,
  isDisabled,
}) {
  return (
    <button
      className={cn(styles.button, className, styles[`variant-${variant}`], {
        [styles.disabled]: isDisabled,
      })}
      onClick={() => !isDisabled && onClick()}
    >
      {title}
    </button>
  );
}
