import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
import { AftermarketIcon } from "../../assets/icons/header";
import PartnersLogos from "../partners-logos/index";

const Header = ({ cls }) => {
  return (
    <header
      className={`container ${styles.header} ${cls && styles.webinarHeader}`}
    >
      <Link to={`/`} reloadDocument>
        <AftermarketIcon className={styles.icon} />
      </Link>
      <PartnersLogos variant={cls && "webinar"} />
    </header>
  );
};

export default Header;
