import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";
import { AftermarketIcon } from "../../assets/icons/header";
import PartnersLogos from "../partners-logos";

const Footer = () => {
  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.footer}>
        <div className={styles.logos}>
          <Link to={`/`} reloadDocument>
            <AftermarketIcon className={styles.icon} />
          </Link>
          <PartnersLogos variant="footer" />
        </div>
        <span className={styles.copyright}>
          Aftermarket Technology Connection © 2024. All rights reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
