import relatedWebinarDefaultImg from "./assets/images/webinar/related-webinar.png";
import honestWrenchesLogo from "./assets/images/webinar/honest-wrenches.png";
import trinityAutoHausLogo from "./assets/images/webinar/trinity-auto-haus.png";

const WEBINARS = [
  {
    url: "lessons-from-trinity-auto-haus",
    startDate: "Mar 7, 2024, 11:00",
    duration: "~1 Hour",
    topic:
      "From Technician to Multi-Shop Owner: Lessons from Trinity Auto Haus",
    image: relatedWebinarDefaultImg,
    registrationLink:
      "https://embed.sequel.io/event/e2f2b7ea-f57b-4c49-8e21-3e233422b3a2",
    host: {
      name: "Trinity Auto Haus",
      description:
        "Trinity Auto Haus has been Waco, Texas’ top car care provider since 2011. Owners Brian and Sarah Jesko have a location on Franklin Ave. and China Spring Road.",
      logo: trinityAutoHausLogo,
    },
    about: [
      "Join Brian Jesko, owner of Trinity Auto Haus, and John Phelps, Director of Channel at Tekmetric, for a fireside chat exploring Brian's journey  from technician to successful multi-shop owner. Gain insights into maintaining a warm and familiar atmosphere while expanding his business, his approach to customer service, and his insights on leveraging online reviews and strategic partnerships for growth. Learn from Brian's 30 years of experience and unique approach to building a thriving auto repair business.",
    ],
    learning: [
      {
        title: (
          <span>
            <b>Leveraging Online Reviews</b>: Tips on managing and responding to
            online reviews to enhance your shop's reputation.
          </span>
        ),
      },
      {
        title: (
          <span>
            <b>Hiring and Team Building</b>: Brian's approach to assembling a
            team that upholds the shop's values and delivers exceptional
            service.
          </span>
        ),
      },
      {
        title: (
          <span>
            <b>Challenges and Solutions in Expansion</b>: How Brian overcame
            growth challenges by understanding his customer demographics and
            adjusting strategies, all while preserving a high level of service
            quality.
          </span>
        ),
      },
      {
        title: (
          <span>
            <b>Building and Maintaining Customer Relationships</b>: Strategies
            for creating a welcoming atmosphere that fosters customer loyalty
            from day one.
          </span>
        ),
      },
    ],
  },
  {
    url: "confessions-of-a-shop-owner",
    startDate: "May 30, 2024, 13:00 EDT",
    duration: "~1 Hour",
    topic: "Confessions of a Shop Owner",
    image: relatedWebinarDefaultImg,
    registrationLink:
      "https://embed.sequel.io/event/6a19ed5d-7539-45cb-be4e-4abca817ee36",
    host: {
      name: "Honest Wrenches",
      description:
        "Serving West Des Moines and its surrounding communities, Honest Wrenches has become synonymous with excellence in automotive care. From routine maintenance to complex repairs, they've got you covered with their skilled team and state-of-the-art facilities.",
      logo: honestWrenchesLogo,
    },
    about: [
      "Join Travis Troy, owner of Honest Wrenches, along with Ward Myers, VP of Training and eServices at Advance Auto Parts, Gregg Rainville, SVP at Steer, and Ian Miller, Partner Manager at Tekmetric, for a fireside chat exploring shop ownership and growth and how defining shop culture has played a major part in that. Learn from Travis’ experience and unique approach to building a thriving auto repair business.",
    ],
    learning: [
      {
        title: (
          <span>
            <b>Defining and developing shop culture</b>: Travis’ approach to
            assembling a team that upholds the shop's values and delivers
            exceptional service and how that translates into customer
            satisfaction.
          </span>
        ),
      },
      {
        title: (
          <span>
            <b>Differentiation in the independent auto repair space</b>: Hear
            Travis’ thoughts on how to differentiate your shop to remain
            competitive in the market.
          </span>
        ),
      },
      {
        title: (
          <span>
            <b>
              Leveraging technology to improve processes and technician
              productivity
            </b>
            : How Travis incorporates technology to support his business, team,
            and shop culture.
          </span>
        ),
      },
    ],
  },
];

export default WEBINARS;
