import React from "react";
import styles from "./Title.module.scss";
import LinkButton from "../button/LinkButton";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

const Title = ({
  status,
  date,
  duration,
  topic,
  description,
  onRegistrationLinkClick,
  isRegistrationActive,
}) => {
  const day = date.toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const time = date.toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.schedule}>
        <div className={styles.status}>{status}</div>
        <div className={styles.detailWrapper}>
          <div className={styles.date}>
            <div className={styles.icon} />
            {day}
          </div>
          <div className={styles.time}>
            <div className={styles.icon} />
            {time}
          </div>
          <div className={styles.duration}>{duration}</div>
        </div>
      </div>
      <h1 className={styles.title}>{topic}</h1>
      {description && <p className={styles.description}>{description}</p>}
      <div className={styles.links}>
        {isRegistrationActive ? (
          <div className={styles.button} onClick={onRegistrationLinkClick}>
            <LinkButton title="Register for Webinar" variant="secondary" />
          </div>
        ) : null}
        <div className={styles.share}>
          SHARE:
          <div className={styles.icons}>
            <FacebookShareButton url={window.location.href}>
              <div className={styles.f} />
            </FacebookShareButton>
            <LinkedinShareButton url={window.location.href}>
              <div className={styles.in} />
            </LinkedinShareButton>
            <TwitterShareButton url={window.location.href}>
              <div className={styles.x} />
            </TwitterShareButton>
            <EmailShareButton url={window.location.href}>
              <div className={styles.email} />
            </EmailShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
