import styles from "./Button.module.scss";
import cn from "classnames";

export default function LinkButton({ title, link, variant }) {
  return (
    <a
      className={cn(styles.button, styles[`variant-${variant}`])}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {title}
    </a>
  );
}
