import React, { useState, useMemo } from "react";
import styles from "./Webinar.module.scss";
import Hero from "./Hero";
import Content from "./Content";
import Footer from "../footer";
import RegistrationModal from "./RegistrationModal";

const Webinar = ({
  allWebinars,
  url,
  startDate,
  duration,
  topic,
  description,
  registrationLink,
  host,
  about,
  learning,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const relatedWebinars = useMemo(
    () => allWebinars.filter((webinar) => webinar.url !== url),
    [allWebinars, url]
  );

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className={styles.wrapper}>
      <Hero
        {...{
          startDate,
          duration,
          topic,
          description,
        }}
        onRegistrationLinkClick={openModal}
      />
      <Content
        {...{
          host,
          about,
          learning,
          startDate,
          relatedWebinars,
        }}
        onRegistrationLinkClick={openModal}
      />
      <Footer />
      <RegistrationModal
        registrationLink={registrationLink}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
};

export default Webinar;
