import React, { useState, useEffect, useCallback } from "react";
import styles from "./Counter.module.scss";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const Counter = ({ date }) => {
  const getRemainedTime = useCallback((date) => {
    let remainedTime = date.getTime() - Date.now();

    const days = Math.floor(remainedTime / DAY);
    remainedTime = remainedTime % DAY;

    const hours = String(Math.floor(remainedTime / HOUR)).padStart(2, "0");
    remainedTime = remainedTime % HOUR;

    const minutes = String(Math.floor(remainedTime / MINUTE)).padStart(2, "0");
    remainedTime = remainedTime % MINUTE;

    const seconds = String(Math.floor(remainedTime / SECOND)).padStart(2, "0");

    return { days, hours, minutes, seconds };
  }, []);

  const [time, setTime] = useState(() => getRemainedTime(date));

  useEffect(() => {
    const intId = setInterval(() => setTime(getRemainedTime(date)), SECOND);
    return () => clearInterval(intId);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <p>Hurry!</p>
        <p>This Webinar Starts In</p>
        <div className={styles.image} />
      </div>
      <div className={styles.cards}>
        <div className={styles.days}>
          <div className={styles.digit}>{time.days}</div>
          <p>DAYS</p>
        </div>
        <div className={styles.hours}>
          <div className={styles.digit}>{time.hours}</div>
          <p>HOURS</p>
        </div>
        <div className={styles.minutes}>
          <div className={styles.digit}>{time.minutes}</div>
          <p>MINUTES</p>
        </div>
        <div className={styles.seconds}>
          <div className={styles.digit}>{time.seconds}</div>
          <p>SECONDS</p>
        </div>
      </div>
    </div>
  );
};

export default Counter;
