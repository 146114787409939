export const SCREEN_MOBILE = 768;
export const SCREEN_TABLET = 1024;
export const SCREEN_LARGE = 1200;
export const SCREEN_EXTRA_LARGE = 1600;
export const SCREEN_VIEW_SIZES = {
  MOBILE: "mobile",
  TABLET: "tablet",
  MIDDLE_PC: "middlePC",
  PERSONAL_COMPUTER: "personalComputer",
  LARGE_PERSONAL_COMPUTER: "largePersonalComputer",
};
