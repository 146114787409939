import React, { useState, useEffect } from "react";

const HubspotRequestAccessForm = () => {
  const [isFormLoading, setIsFormLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js-eu1.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "6191634",
          formId: "6d316d49-eb3d-46e1-8142-bbe60366ce6d",
          target: "#hubspotForm",
          onFormReady: setIsFormLoading(false),
        });
      }
    });
  }, []);

  return (
    <div style={isFormLoading ? { display: "none" } : { width: "100%" }}>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubspotRequestAccessForm;
