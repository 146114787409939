import React from "react";
import styles from "./PartnerCard.module.scss";
import cn from "classnames";

const PartnerCard = ({ title, description, clsImg, clsLogo, children }) => {
  return (
    <div className={styles.partnerCard}>
      <div className={styles.cardHeader}>
        <div className={cn(styles.cardHeaderImg, styles[clsImg])}></div>
      </div>
      <div className={styles.cardBody}>
        <div className={cn(styles.cardLogo, styles[clsLogo])}></div>
        <div className={styles.cardTitle}>{title}</div>
        <div className={styles.cardDescription}>{description}</div>
      </div>
      <div className={styles.buttonWrap}>{children}</div>
    </div>
  );
};

export default PartnerCard;
