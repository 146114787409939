import React from "react";
import styles from "./Home.module.scss";
import Hero from "../hero";
import ContentSection from "../content-section";
import Footer from "../footer";

const Home = () => {
  return (
    <div className={styles.wrapper}>
      <Hero />
      <ContentSection />
      <Footer />
    </div>
  );
};

export default Home;
