import React from "react";
import styles from "./SubscribePopup.module.scss";
import iconSuccess from "../../assets/icons/subscribe-popup/i-success.svg";

export const Success = ({ meetingInfo }) => {
  const { timestamp, name } = meetingInfo;
  const date = new Date(timestamp);
  const timeString = `${date.toLocaleString("en-us", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })}\n${date.toLocaleString("en-us", {
    hour: "numeric",
    minute: "numeric",
  })}`;

  return (
    <div className={styles.meetingsFormWrapper}>
      <div className={styles.meetingsFormContainer}>
        <div className={styles.success}>
          <img className={styles.iconSuccess} src={iconSuccess} alt="success" />
          <h1 className={styles.title}>Demo Scheduled!</h1>
          <p className={styles.body}>
            {`Your demo is booked with ${name}.\nAn invitation has been emailed to you.`}
          </p>
          <div className={styles.time}>{timeString}</div>
        </div>
      </div>
    </div>
  );
};
