import React, { useState } from "react";
import cn from "classnames";
import styles from "./RegistrationModal.module.scss";

const RegistrationModal = ({ onClose, registrationLink, isOpen }) => {
  const [isExpanded, setExpanded] = useState(false);
  const handleExpandClick = () => setExpanded((prev) => !prev);
  const handleCloseClick = () => {
    setExpanded(false);
    onClose();
  };
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) handleCloseClick();
  };

  return (
    <div
      className={cn(styles.background, { [styles.open]: isOpen })}
      onClick={handleBackgroundClick}
    >
      <div className={cn(styles.wrapper, { [styles.expanded]: isExpanded })}>
        <div className={styles.formContainer}>
          <iframe
            title="Sequel event"
            src={registrationLink}
            frameborder="0"
            allow="camera *; microphone *; display-capture *"
            allowfullscreen
            style={{ width: "100%", height: "100%" }}
          >
            {" "}
          </iframe>
        </div>
        <div className={styles.buttonsContainer}>
          <div
            className={cn(styles.button, styles.close)}
            onClick={handleCloseClick}
          />
          <div
            className={cn(
              styles.button,
              isExpanded ? styles.collapse : styles.expand
            )}
            onClick={handleExpandClick}
          />
        </div>
      </div>
    </div>
  );
};

export default RegistrationModal;
