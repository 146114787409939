import TagManager from "react-gtm-module";

const ANALYTICS_EVENTS = {
  getEarlyAccessClick: "get_early_access_click",
  getEarlyAccessPopupOpened: "get_early_access_popup_opened",
  getEarlyAccessFormShown: "get_early_access_form_shown",
  getEarlyAccessFormSubmitted: "get_early_access_form_submitted",
};

const GMA_ID = "GTM-T295T34";

const tagManagerArgs = {
  gtmId: GMA_ID,
};

const initGTM = () => {
  TagManager.initialize(tagManagerArgs);
};

let addAnalyticsEvent = (eventName) => {};

if (typeof window !== `undefined`) {
  window.dataLayer = window.dataLayer || [];
  addAnalyticsEvent = (eventName) => {
    window.dataLayer.push({ event: eventName });
  };
}

export { ANALYTICS_EVENTS, addAnalyticsEvent, initGTM };
