import React, { useState } from "react";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import styles from "./ContentSection.module.scss";
import PartnerCard from "../partner-card";
import Button from "../button/Button";
import LinkButton from "../button/LinkButton";
import SubscribePopup from "../subscribe-popup";
import {
  ANALYTICS_EVENTS,
  addAnalyticsEvent,
} from "../../services/analyticsService";

const PARTNERS = [
  {
    title: "Cloud-based Shop Management System",
    description:
      "Grow your business with technology that sets you apart from competitors. The modern shop management system from Tekmetric provides real-time reporting that helps you grow your margins and increase your ARO. Use the cloud-based system to make decisions for your business anytime, anywhere.",
    clsImg: "tekmetric",
    clsLogo: "tekmetricLogo",
    btnTitle: "Get a Demo",
    link: "https://meetings.hubspot.com/tarmstrong11/2023-mktg-a-atc",
  },
  {
    title: "Grow Revenue and Customer Loyalty",
    description:
      "Steer is changing how auto shops communicate with their customers through their automated retention marketing and customer communication platform. Send professionally designed service reminders and custom text and email campaigns to keep your customers coming back for their next service.",
    clsImg: "steer",
    clsLogo: "steerLogo",
    btnTitle: "Get a Demo",
  },
  {
    title: "Local Service. Nationwide Network",
    description:
      "No matter how big or small the job, we’ve got you.  From starters and struts, and everything in between, our parts are made with quality materials, engineering, and testing that we can both proudly stand behind.  Alongside Steer and Tekmetric, we are committed to helping you take your shop to the next level with solutions designed to boost your business, better serve your customers, and the training to ensure your team is ready to meet the evolving needs of tomorrow.",
    clsImg: "localService",
    clsLogo: "localServiceLogo",
    btnTitle: "Learn More",
    link: "https://my.advancepro.com/?utm_source=partner_website_steer&utm_medium=referral+&utm_campaign=atc_landing_page",
  },
  {
    title: "Professional Quality Parts, Service and Solutions",
    description:
      "TechNet Professional allows independent shops to be part of a nationwide network, while maintaining their unique identity in the local community. Members have access to exclusive solutions to grow their business and serve their customers better, including 24 month/ 24,000 mile Nationwide Warranty, Roadside Assistance and customer promotions",
    clsImg: "technet",
    clsLogo: "technetLogo",
    btnTitle: "Learn More",
    link: "https://www.technetprofessional.com/members?utm_source=partner_website_steer&utm_medium=referral+&utm_campaign=atc_landing_page",
  },
  {
    title: "Technical and Business Management Training",
    description:
      "Equip your shop for evolving vehicle repair needs with CTI+WTI's top-notch technical and business training. Choose from online, virtual, or in-person training, with career pathways from apprentice to master technician.",
    clsImg: "ctiWti",
    clsLogo: "ctiWtiLogo",
    btnTitle: ["CTI", "WTI"],
    link: [
      "http://www.ctionline.com?utm_source=partner_website_steer&utm_medium=referral+&utm_campaign=atc_landing_page",
      "http://www.wtitraining.com?utm_source=partner_website_steer&utm_medium=referral+&utm_campaign=atc_landing_page",
    ],
  },
  {
    title: "OEM Service Information",
    description:
      "MotoLogic puts unedited OEM service information at your fingertips so you can confidently diagnose and repair. In addition to OEM content and wiring diagrams, MotoLogic gives you access to MotoVisuals, MOTOR labor, quick specs, and color wiring diagrams.",
    clsImg: "motologic",
    clsLogo: "motologicLogo",
    btnTitle: "Learn More",
    link: "http://www.motologic.com?utm_source=partner_website_steer&utm_medium=referral+&utm_campaign=atc_landing_page",
  },
  {
    title: "3D Service and Repair Animations",
    description:
      "Explaining repairs just got easier. With more than 400 3D service and repair animations, including ADAS, brake systems, hybrid, EV, and more, incorporating MotoVisuals in your digital vehicle inspection creates transparency with your customers and increases your shop’s close rate.",
    clsImg: "motovisuals",
    clsLogo: "motovisualsLogo",
    btnTitle: "Learn More",
    link: "http://www.motovisuals.com?utm_source=partner_website_steer&utm_medium=referral+&utm_campaign=atc_landing_page",
  },
  {
    title: "Shop Website Services",
    description:
      "Launch a professional and affordable website specifically designed for your shop that is mobile-friendly and includes tools and services like an appointment scheduler and a personalized online garage for your customers.",
    clsImg: "motoweb",
    clsLogo: "motowebLogo",
    btnTitle: "Learn More",
    link: "https://my.advancepro.com/service/s/apro-motoweb?language=en_US&utm_medium=referral%20&utm_campaign=atc_landing_page",
  },
];

const ContentSection = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.sectionTitleWrap}>
            <h3 className={styles.sectionTitle}>Seize the Opportunity</h3>
            <p className={styles.sectionDescription}>
              As technology transforms the aftermarket, we're here to make you
              the industry authority. With Advance's parts distribution and
              technology, Tekmetric's shop management software, and Steer's CRM,
              we're a one-stop solution. Together, we're building an
              all-encompassing solution ecosystem for the modern repair shop
              that helps you increase profits, create better experiences for
              customers, and improve shop efficiency.
            </p>
          </div>

          <div className={styles.cardsWrapper}>
            {PARTNERS.map(
              ({ title, description, link, clsImg, clsLogo, btnTitle }, i) => (
                <PartnerCard
                  title={title}
                  description={description}
                  key={i}
                  clsLogo={clsLogo}
                  clsImg={clsImg}
                >
                  {i === 1 ? (
                    <Button
                      title={btnTitle}
                      onClick={() => {
                        addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessClick);
                        setOpen(true);
                      }}
                    />
                  ) : i === 4 ? (
                    link.map((link, i) => (
                      <LinkButton
                        title={btnTitle[i]}
                        link={link}
                        variant="halfWidth"
                        key={i}
                      />
                    ))
                  ) : (
                    <LinkButton title={btnTitle} link={link} />
                  )}
                </PartnerCard>
              )
            )}
          </div>
        </div>
      </div>
      {open && (
        <>
          <RemoveScrollBar gapMode="padding" />
          <SubscribePopup close={handleClose} />
        </>
      )}
    </>
  );
};

export default ContentSection;
