import React from "react";
import styles from "./SubscribePopup.module.scss";
import logo from "../../assets/icons/subscribe-popup/logo-popup.svg";
import rating from "../../assets/icons/subscribe-popup/i-stars-white.svg";
import iconItemCheck from "../../assets/icons/subscribe-popup/i-item-checked.svg";
import { HSplit } from "../divider";
import Carousel from "./Carousel";

export const About = ({ onClose }) => {
  return (
    <div className={styles.about}>
      <header className={styles.aboutHeader}>
        <img src={logo} alt="steer logo" className={styles.aboutLogo} />
        <div className={styles.aboutRate}>
          <img src={rating} alt="rating" />
          <p>Rated 5 stars by current users</p>
        </div>
      </header>
      <div className={styles.dividerWrap}>
        <HSplit className={styles.divider} />
      </div>
      <ul className={styles.aboutList}>
        <li className={styles.aboutListItem}>
          <img src={iconItemCheck} alt="icon checked" />
          See immediate results
        </li>
        <li className={styles.aboutListItem}>
          <img src={iconItemCheck} alt="icon checked" />
          No long-term commitment necessary
        </li>
        <li className={styles.aboutListItem}>
          <img src={iconItemCheck} alt="icon checked" />
          Dedicated marketing specialist with every account
        </li>
      </ul>
      <div className={styles.sliderWrap}>
        <Carousel />
      </div>
      <HSplit className={styles.divider} />
      <footer className={styles.aboutFooter}>
        <span>Need more info?</span>
        <a
          href="https://steercrm.com/support-request"
          target="_blank"
          rel="noreferrer"
        >
          <button className={styles.btnTalkUs} onClick={onClose}>
            Talk to Us
          </button>
        </a>
      </footer>
    </div>
  );
};
